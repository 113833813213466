import React from 'react';
import { connect } from "react-redux";
import pathOr from "ramda/src/pathOr";
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

const useStyles = makeStyles(theme => ({
    content: {
        overflow: 'hidden',
    }
}));

export default connect(null, null)((props) => {
    const classes = useStyles();

    const handleSubmit = event => {
        props.onSave()
        props.onClose()
    }

    return (
        <Dialog open={props.open} onClose={props.onClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Apply Security Settings Globally?</DialogTitle>
            <DialogContent className={classes.content}>
                Changes made here will affect password requirements for all admin panel users.
          </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose}>
                    Cancel
            </Button>
                <Button onClick={handleSubmit} color="secondary">
                    Confirm
            </Button>
            </DialogActions>
        </Dialog>
    );
})