import React from 'react';
import Routes from './Routes';
import blue from '@material-ui/core/colors/blue';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import { setAuth, getToken } from './sagas/httpHelper.js';

const theme = createMuiTheme({
  palette: {
    primary: blue,
  },
});

function App() {
  setAuth(getToken());
  return (
    <ThemeProvider theme={theme}>
      <Routes />
    </ThemeProvider>
  );
}

export default App;
