export default {
  auth: {
    token: null,
    email: null,
    firstname: null,
    lastname: null,
    speciality: null,
    role: "",
    created_at: null,
    loading: false,
    error: false,
    tempToken: "",
    host: "",
    sigRequest: "",
    loginSucceeded: false
  },
  dashboard: {
    loading: true,
    results: [],
  },
  clubFoot: {
    user: {
      parent: {}
    },
    daily: [],
    weekly: [],
    monthly: [],
    loading: true,
    results: []

  },
  scoliosis: {
    user: {
      parent: {},
      patient: {}
    },
    daily: [],
    weekly: [],
    monthly: [],
    loading: true,
    results: []
  },
  admin: {
    loading: true
  },
  passwordConfig: {
    minlength: 6,
    maxlength: 20,
    isspcharrequired: false,
    isnumrequired: false,
    iscapletterrequired: false,
    numfailedattempts: 0,
    numrememberedpasswords: 0
  }
};
