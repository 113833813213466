import { withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import { palette } from '../../styles/variables';

export default withStyles({
  root: {
    borderBottom: `1px solid ${palette.border}`,
    padding: '0 17px',
  },
  indicator: {
    backgroundColor: palette.pantone,
    height: '4px',
    borderRadius: '2px',
  },
})(Tabs);
