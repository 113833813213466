import initState from "./initialState";
import { GET_PASSWORD_CONFIG_SUCCEEDED, GET_PASSWORD_CONFIG_FAILED } from "../actions/actionTypes";

const reducer = (state = initState.passwordConfig, action) => {
    console.log('action', action.type, action)
    switch (action.type) {
        case GET_PASSWORD_CONFIG_SUCCEEDED:
            state = {
                ...action.data,
                loading: false
            }
            return state;
        case GET_PASSWORD_CONFIG_FAILED:
            state = {
                ...state,
                loading: false
            }
            return state
        default:
            return state;
    }
};

export default reducer;
