import Swal from "sweetalert2";

export const validatePassword = (passwordConfig, password) => {
  // if (password === undefined) return true;
  /* eslint-disable */
  if (password.length < passwordConfig.minlength || password.length > passwordConfig.maxlength) return false;
  if (passwordConfig.isspcharrequired) {
    const spchar_regex = /[\s~`!@#$%\^&*+=\-\[\]\\';,/{}|\\":<>\?()\._]/g;
    if (!spchar_regex.test(password)) return false;
  }
  if (passwordConfig.isnumrequired) {
    const num_regex = /\d/g;
    if (!num_regex.test(password)) return false;
  }
  if (passwordConfig.iscapletterrequired) {
    const capletter_regex = /(?=.*[A-Z])/g;
    if (!capletter_regex.test(password)) return false;
  }
  return true;
};

export const validateEmail = email => {
  if (email === undefined) return true;
  /* eslint-disable */
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const showLoading = (text) => {
  Swal.fire({
    type: 'info',
    title: '',
    text,
    allowOutsideClick: false
  });

  Swal.showLoading();
}

export const hideLoading = () => {
  if (Swal.isVisible()) {
    Swal.hideLoading();
    Swal.close();
  }
}
