import React from "react"
import { connect } from "react-redux";
import pathOr from "ramda/src/pathOr";
import styled from "styled-components"
import InfoBox from "./InfoBox";
import Graphs from "../../app/Graphs";
import PatientStatusBox from "../../../components/PatientStatusBox";
import { getClubFootUserDetails, updateClubFootUser, deleteClubFootUser } from '../../../actions/clubFoot.js'
import moment from 'moment';

const InfoBoxContainer = styled.div`
  margin-top: 25px;
`
const mapStateToProps = state => ({
  user: pathOr({}, ["clubFoot", "user"], state),
  users: pathOr({}, ["clubFoot", "users"], state),
  // daily: getDailyData(pathOr({}, ["clubFoot", "monthly"], state)),
  // weekly: pathOr({}, ["clubFoot", "monthly"], state).slice(0, 49),
  // monthly: pathOr({}, ["clubFoot", "monthly"], state),

  daily: getDailyData(getMonthlyDataFromRaw(pathOr({}, ["clubFoot", "monthlyRaw"], state))),
  weekly: getMonthlyDataFromRaw(pathOr({}, ["clubFoot", "monthlyRaw"], state)).slice(0, 49),
  monthly: getMonthlyDataFromRaw(pathOr({}, ["clubFoot", "monthlyRaw"], state)),
});

const mapDispatchToProps = dispatch => {
  return {
    getClubFootUserDetails: (id) => dispatch(getClubFootUserDetails(id)),
    updateClubFootUser: (user) => dispatch(updateClubFootUser(user)),
    deleteClubFootUser: (id) => dispatch(deleteClubFootUser(id))
  }
}

function inRange(element, range) {
  if (element >= range.min && element <= range.max) return true
  else return false
}

function outRange(element, range) {
  if (element <= range.min || element >= range.max) return true
  else return false
}

function addDonnedData(data, date, element, elementDate) {
  let currInterval = 900

  let elementDayRange = {
    min: elementDate.set({ hours: 6, minutes: 0, seconds: 1 }).toDate(),
    max: elementDate.set({ hours: 17, minutes: 0, seconds: 0 }).toDate()
  }
  let elementEveningRange = {
    min: elementDate.set({ hours: 17, minutes: 0, seconds: 1 }).toDate(),
    max: elementDate.set({ hours: 22, minutes: 0, seconds: 0 }).toDate()
  }

  data.seconds += currInterval
  let elementTime = moment(date + ' ' + element.time)
  if (inRange(elementTime, elementDayRange)) {
    //console.log(`Sorting to day: ${date} ${element.time}`)
    data.day += currInterval
  }
  else if (inRange(elementTime, elementEveningRange)) {
    //console.log(`Sorting to evening: ${date}  ${element.time}`)
    data.evening += currInterval
  }
  else {
    //console.log(`Sorting to sleep: ${date}  ${element.time}`)
    data.sleep += currInterval
  }
}

function getMonthlyDataFromRaw(response) {
  console.log("test 1234567890", response)
  if (!response || response.length == 0) return response

  let data = response.data
  //This needs to be worked into addDonnedData later
  let currInterval = 900

  let donningData = {}
  let pW = 8
  let donned = false

  let donningTemp = { min: 81, max: 95 }

  let donningThreshhold = { min: donningTemp.min - 5, max: donningTemp.max + 4 }
  let doffingThreshhold = { min: donningTemp.min - 2, max: donningTemp.max + 4 }

  //console.log(data)

  for (let date in data) {
    donningData[date] = { seconds: 0, day: 0, evening: 0, sleep: 0 }
    let elementDate = moment(date + ' 00:00:00')
    
    data[date].forEach((element, index, array) => {
      //console.log(date, element.time, element.temp, donned)
      if (inRange(element.temp, donningTemp)) {
        //console.log('Within temperature range')
        //Handler if it's within the temperature range
        if (donned) {
          //If donned is true, just keep on adding records
          //console.log('Already donned, so add records')
          addDonnedData(donningData[date], date, element, elementDate)
        }
        else {
          //console.log('Not already donned, so check and see if it should flip.')
          //If donned is false, we need to do a series of checks to see if 
          //we should set donned to true and retroactively convert some records
          if (index == array.length - 1) {
            //This is the last record, do nothing
            //console.log('last record, so do nothing')
          } else {
            //console.log('checking trailing')
            //Check the trailing data first to make sure it's within range
            //which should indicate that they put it on and kept wearing it
            var nextInRange = true;
            let nextData = array.slice(index + 1, index + 3)
            //console.log(nextData)
            for (let nIndex = 0; nIndex < nextData.length; nIndex++) {
              if (outRange(nextData[nIndex].temp, donningTemp)) {
                //If any of the trailing data aren't in range, it doesn't count as donned
                //console.log(`One or more trailing temps (${nextData[nIndex].temp}) are outside range; not donned`)
                nextInRange = false;
                //break;
              }
            }
            if (nextInRange) {
              //Now we have to look at leading data to see if it's
              //gone up by 5+ degrees or down by 4+ degrees
              //console.log('Checking leading')
              var enoughVariance = false
              let prevStart = index - 5
              let prevEnd = index
              if (prevStart < 0) {
                //This is here in case there's less than 4 leading intervals
                prevEnd = prevEnd + prevStart
                prevStart = 0
              }

              let prevData = array.slice(prevStart, prevEnd)
              //console.log(prevData)
              for (let pIndex = 0; pIndex < prevData.length; pIndex++) {
                if (!inRange(prevData[pIndex].temp, donningThreshhold)) {
                  //We're looking for a record that's outside the thresholds to make sure the temp went up/down
                  //As long as there's one, we let it go; this probably needs to be tightened up more.
                  enoughVariance = true;
                  //console.log(`Enough variance: ${enoughVariance} ${prevData[pIndex].temp}`)
                }
              }

              if (enoughVariance) {
                //We've passed the leading and trailing tests, 
                //so now we set the donned flag to true and
                //adjust the leading interval data.
                //Trailing interval data is not adjusted
                //console.log("Leading and trailing tests passed.")
                donned = true
                addDonnedData(donningData[date], date, element, elementDate)
                for (var m = 0; m > 2; m++) {
                  if (m < index) {
                    //This needs to be worked into addDonnedData later
                    let incTime = moment(date + ' ' + element.time)
                    let lDayStart = moment(incTime.format()).set("hours", 6).set("minutes", 0).set("seconds", 1)
                    let lDayEnd = moment(incTime.format()).set("hours", 17).set("minutes", 0).set("seconds", 0)
                    let lEveningStart = moment(incTime.format()).set("hours", 17).set("minutes", 0).set("seconds", 1)
                    let lEveningEnd = moment(incTime.format()).set("hours", 22).set("minutes", 0).set("seconds", 0)
                    //Sometimes i is the first or second record, so we need to bail if they are
                    let intTime = moment(incTime).subtract(currInterval * (m + 1), "seconds")
                    donningData[`${intTime.format("YYYY-MM-DD")}`].seconds += currInterval
                    if (intTime.isBetween(lDayStart, lDayEnd, undefined, '[]')) {
                      donningData[`${intTime.format("YYYY-MM-DD")}`].daySeconds += currInterval
                    } else if (intTime.isBetween(lEveningStart, lEveningEnd, undefined, '[]')) {
                      donningData[`${intTime.format("YYYY-MM-DD")}`].eveningSeconds += currInterval
                    } else {
                      donningData[`${intTime.format("YYYY-MM-DD")}`].nightSeconds += currInterval
                    }
                  }
                }
              }
            }
          }
        }
      } else if (!inRange(element.temp, doffingThreshhold)) {
        //Handler if it's way outside temperature range
        //console.log('way outside temp range')
        if (donned) {
          //console.log('check to see if we should flip donned off')
          //If donned is false, we just don't do anything, 
          //but if donned is true, we need to do checks
          //to see if we should set donned to false, ignore this entry
          //and invalidate some previous ones, or just log as normal
          var shouldDoff = false
          if (index == array.length - 1 || index == array.length - 2) {
            //This is the last or next to last record, consider it doffed
            //console.log('This is one of the last few records, so we should doff')
            shouldDoff = true
          } else {
            //Otherwise, check the next two records and see if both outside 
            //console.log('At least two records to go')
            let temp1 = array[index + 1].temp
            let temp2 = array[index + 2].temp
            //console.log(`${temp1} ${temp2}`)
            if (outRange(temp1, doffingThreshhold)
              && outRange(temp2, doffingThreshhold)) {
              shouldDoff = true
            }
          }
          if (shouldDoff) {
            if (index > 0) {
              //Do doffing logic here
              //This needs to be worked into addDonnedData later
              let incTime = moment(date + ' ' + element.time)
              let lDayStart = moment(incTime.format()).set("hours", 6).set("minutes", 0).set("seconds", 1)
              let lDayEnd = moment(incTime.format()).set("hours", 17).set("minutes", 0).set("seconds", 0)
              let lEveningStart = moment(incTime.format()).set("hours", 17).set("minutes", 0).set("seconds", 1)
              let lEveningEnd = moment(incTime.format()).set("hours", 22).set("minutes", 0).set("seconds", 0)
              let intTime = moment(incTime).subtract(currInterval, "seconds")
              donningData[`${intTime.format("YYYY-MM-DD")}`].seconds += currInterval
              if (intTime.isBetween(lDayStart, lDayEnd, undefined, '[]')) {
                donningData[`${intTime.format("YYYY-MM-DD")}`].daySeconds += currInterval
              } else if (intTime.isBetween(lEveningStart, lEveningEnd, undefined, '[]')) {
                donningData[`${intTime.format("YYYY-MM-DD")}`].eveningSeconds += currInterval
              } else {
                donningData[`${intTime.format("YYYY-MM-DD")}`].nightSeconds += currInterval
              }
            }
            donned = false

          } else {
            //Doesn't meet the criteria for doffing, so we log it as per usual
            addDonnedData(donningData[date], date, element, elementDate)

          }
        }
      } else if (donned) {
        //console.log('Already donned, not too far outside range')
        //This is the fall through if it's not in either of the other two ranges
        addDonnedData(donningData[date], date, element, elementDate)
      }
    })
  }

  let endWeek = moment().endOf('week')
  let all_data = []

  for (let day = 0; day < 189; day++) {
    let dateStr = endWeek.subtract(1, 'd').toDate().toISOString().split('T')[0]
    let dayData = donningData[dateStr]
    let dataObj = {}
    if (dayData) dataObj[dateStr] = { day: dayData.day / 3600, evening: dayData.evening / 3600, sleep: dayData.sleep / 3600, week_number: parseInt(day / 7 + 1) }
    else dataObj[dateStr] = { day: 0, evening: 0, sleep: 0, week_number: parseInt(day / 7 + 1) }
    all_data.push(dataObj)
  }
  //console.log(donningData)

  return all_data
}

function getDailyData(data) {
  if (!data || data.length == 0) return data

  let dateKeys = []
  for (var i = 0; i < 7; i++) {
    let date = new Date()
    date.setDate(date.getDate() - (6 - i))
    dateKeys.push(getDateKey(date.getFullYear(), date.getMonth() + 1, date.getDate()))
  }

  let daily_data = []
  for (var j = 0; j < 7; j++) {
    for (var i = 0; i < 14; i++) {
      if (dateKeys[j] == Object.keys(data[i])[0]) {
        daily_data.push(data[i])
      }
    }
  }

  return daily_data
}

function getDateKey(year, month, date) {
  let dateKey = `${year}-`
  if (month > 9) {
    dateKey += `${month}-`
  } else {
    dateKey += `0${month}-`
  }
  if (date > 9) {
    dateKey += `${date}`
  } else {
    dateKey += `0${date}`
  }
  return dateKey
}

const ClubFootPatient = (props) => {
  React.useEffect(() => {
    props.getClubFootUserDetails(props.match.params.id)
  }, [props.match.params.id])

  const updateUser = (values) => {
    let user = {
      firstname: values.firstname,
      lastname: values.lastname,
      email: values.email,
      contact_number: values.contact_number,
      sensor_id: values.sensor_id,
      id: values.user_id
    }
    props.updateClubFootUser(user)
  }

  return (
    <>
      <PatientStatusBox users={props.users} userId={props.match.params.id} user={props.user} />
      <InfoBoxContainer>
        <InfoBox user={props.user} updateUser={updateUser} deleteUser={props.deleteClubFootUser} />
      </InfoBoxContainer>
      <InfoBoxContainer>
        <Graphs daily={props.daily} weekly={props.weekly} monthly={props.monthly} users={props.users} userId={props.match.params.id} />
      </InfoBoxContainer>
    </>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(ClubFootPatient);
