import React from 'react';
import { connect } from "react-redux";
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { logout } from "../../actions/auth";

const useStyles = makeStyles(theme => ({
    content: {
        overflow: 'hidden',
    }
}));

const mapDispatchToProps = dispatch => ({
    logout: () => dispatch(logout())
});

export default connect(null, mapDispatchToProps)((props) => {
    const classes = useStyles();

    return (
        <Dialog open={props.open} onClose={props.onClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Session Timeout</DialogTitle>
            <DialogContent className={classes.content}>
                Your session is about to expire due to inactivity.  Do you want to extend your session?
            </DialogContent>
            <DialogActions>
                <Button onClick={() => props.logout()}>
                    Logout
                </Button>
                <Button onClick={() => props.onClose()} color="secondary">
                    Stay Logged In
                </Button>
            </DialogActions>
        </Dialog>
    );
})