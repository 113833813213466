import React from 'react';
import { connect } from "react-redux";
import pathOr from "ramda/src/pathOr";
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Dropzone from 'react-dropzone';
import { importClubFootPatientData } from '../../actions/dashboard.js'
// import ImportDropzone from "../../components/Dropzone";

const DropzoneContainer = styled.div`
    border-radius: 4px;
    border: dashed 2px black;
    background: white;
`

const Div = styled.div`
    padding: 100px;
    background: rgba(0,0,0,0.04);
`
const DropzoneBigMessage = styled.p`
    text-align: center;
    font-size: 24px;
    font-style: normal;
    line-height: 36px;
`
const DropzoneSmallMessage = styled.p`
    text-align: center;
    font-size: 14px;
    line-height: 21px;
    text-decoration-line: underline;
`


const useStyles = makeStyles(theme => ({
    textField: {
        width: 250,
    },
    radioFormControl: {
        marginTop: 25 + 30,
    },
    formControl: {
        marginTop: 16 + 30,
    },
    content: {
        overflow: 'hidden',
    },
    passwordFormControl: {
        marginTop: 16,
    }
}));

const mapDispatchToProps = dispatch => {
    return {
        importClubFootPatientData: (file) => dispatch(importClubFootPatientData(file)),
    }
}

const mapStateToProps = state => ({
    passwordConfig: pathOr({}, ["passwordConfig"], state)
});


export default connect(mapStateToProps, mapDispatchToProps)((props) => {
    const classes = useStyles();
    const [values, setValues] = React.useState({
        addedFile: null,
        perscribedHours: null
    });
    const [fileNames, setFileNames] = React.useState([]);
    const handleDrop = acceptedFiles =>
        setFileNames(acceptedFiles.map(file => file.name));

    const handleChange = name => event => {
        setValues({ ...values, [name]: event.target.value });
    };

    const handleSubmit = event => {
        props.importClubFootPatientData(values.addedFile)
    }

    const onDrop = (acceptedFiles) => {
        setValues({ ...values, addedFile: acceptedFiles[0] })
        acceptedFiles.forEach((file) => {
            const reader = new FileReader()

            reader.onabort = () => console.log('file reading was aborted')
            reader.onerror = () => console.log('file reading has failed')
            reader.onload = () => {
                // Do whatever you want with the file contents
                const binaryStr = reader.result
                // setValues({ ...values, addedFile: reader.result })
            }
            reader.readAsText(file)

        })

    }

    return (
        <div>
            <input style={{ opacity: 0, position: 'absolute' }} />
            <input type="password" style={{ opacity: 0, position: 'absolute' }} />
            <Dialog open={props.open} onClose={props.onClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="import-dialog-title">Add New Sensor Data</DialogTitle>
                <DialogContent className={classes.content}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <DropzoneContainer>
                                <Dropzone onDrop={onDrop} >
                                    {({ getRootProps, getInputProps }) => (
                                        <Div {...getRootProps()}>
                                            <input {...getInputProps()} />
                                            <DropzoneBigMessage>Drag CSV File Here</DropzoneBigMessage>
                                            <DropzoneSmallMessage>or click here to browse</DropzoneSmallMessage>
                                        </Div>
                                    )}
                                </Dropzone>
                                {/* <ImportDropzone /> */}
                            </DropzoneContainer>

                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                id="perscribedHours"
                                className={classes.textField}
                                label="Perscribed Hours"
                                value={values.perscribedHours}
                                onChange={handleChange('perscribedHours')}
                                margin="normal"
                                variant="filled"
                                type="number"
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.onClose}>
                        Cancel
                    </Button>
                    <Button
                        onClick={(e) => {
                            handleSubmit(e)
                            props.onClose()
                        }}
                        color="secondary"
                    >
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
})