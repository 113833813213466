import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import authReducer from './authReducer';
import adminReducer from './adminReducer';
import dashboardReducer from './dashboardReducer';
import scoliosisReducer from './scoliosisReducer';
import clubFootReducer from './clubFootReducer';
import passwordConfigReducer from './passwordConfigReducer';

const createRootReducer = (history) => combineReducers({
  router: connectRouter(history),
  auth: authReducer,
  admin: adminReducer,
  dashboard: dashboardReducer,
  scoliosis: scoliosisReducer,
  clubFoot: clubFootReducer,
  passwordConfig: passwordConfigReducer,
})

export default createRootReducer
