import React from "react";
import styled from "styled-components";
import moment from "moment";
import { palette } from "../../styles/variables";

const Container = styled.div`
  width: 256px;
  padding: 18px 24px 12px;
  background-color: ${palette.blue};
  border-radius: 4px;
`

const Year = styled.p`
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: 0.15px;
  color: white;
`

const Date = styled.p`
  font-size: 34px;
  line-height: 1.5;
  letter-spacing: 0.25px;
  color: rgba(255, 255, 255, 0.7);
`

export default () => {
  return (
    <Container>
      <Year>{moment().format('YYYY')}</Year>
      <Date>{moment().format('ddd, D MMM')}</Date>
    </Container>
  )
}
