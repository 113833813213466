import React from "react"
import { Route, Switch } from "react-router-dom";
import LoginV1 from "./containers/login/v1";
import LoginV2 from "./containers/login/v2";
import ForgotPassword from "./containers/forgot-password";
import SetPassword from "./containers/forgot-password/setPassword.js";
import App from "./containers/app";
import PrivateRoute from "./components/PrivateRoute";

export default () => (
  <Switch>
    <Route exact path="/login" component={LoginV2} />
    {/* <Route exact path="/login-v2" component={LoginV2} /> */}
    <Route exact path="/forgot-password" component={ForgotPassword} />
    <Route exact path="/set-password" component={SetPassword} />
    <PrivateRoute path="/" component={App} />
    <Route render={() => <div>404</div>} />
  </Switch>
)