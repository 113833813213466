import React from "react"
import pathOr from "ramda/src/pathOr";
import { push } from "connected-react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { palette } from "../../styles/variables";
import Logo from "../../assets/images/logo.png";
import IconDashboard from "./IconDashboard";
import IconFootstep from "./IconFootstep";
import IconSpine from "./IconSpine";
import IconStethscope from "./IconStethscope";
import LockIcon from '@material-ui/icons/Lock';
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import { logout } from "../../actions/auth";

const Container = styled.div`
  position: fixed;
  width: 255px;
  height: 100%;
  border-right: 1px solid ${palette.border};
`

const TopContainer = styled.div`
  border-bottom: 1px solid ${palette.border};
  text-align: center;
  padding: 24px 16px 14px;
  img {
    width: 154px;    
  }
`

const Label = styled.p`
  font-size: 10px;
  line-height: 1.2;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  margin-top: 10px;
  text-align: left;
`

const Name = styled.p`
  font-size: 20px;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: 0.15px;
  color: rgba(0, 0, 0, 0.87);
  text-align: left;
`

const Nav = styled.div`
  padding: 10px;
`

const StyledLink = styled(NavLink)`
  margin: 10px 0;
  height: 40px;
  display: flex;
  align-items: center;
  svg {
    margin-right: 32px;
    margin-left: 8px;
  }
  span {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.5;
    letter-spacing: 0.1px;
    color: rgba(0, 0, 0, 0.87);
  }

  &.selected,
  &:hover {
    background-color: ${palette.skyBlue};
    border-radius: 4px;
    span {
      color: ${palette.blue};
    }
    svg {
      path {
        fill: ${palette.blue};
      }
    }
  }
`

const LogoutContainer = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 14px 16px 34px;
  border-top: 1px solid ${palette.border};
  box-sizing: border-box;
  label {
    font-size: 14px;
    line-height: 1.5;
    letter-spacing: 0.25px;
    color: ${palette.gray};
  }
`

const Logout = styled.div`
  height: 40px;
  display: flex;
  align-items: center;
  cursor: pointer;
  svg {
    margin-right: 32px;
    margin-left: 8px;
  }
  span {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.5;
    letter-spacing: 0.1px;
    color: rgba(0, 0, 0, 0.87);
  }
`

const mapStateToProps = state => ({
  auth: pathOr({}, ["auth"], state),
});

const mapDispatchToProps = dispatch => ({
  logout: () => dispatch(logout())
});


export default connect(mapStateToProps, mapDispatchToProps)(({ logout, auth }) => {
  const isAdminActive = (match, location) => {
    if (!match) {
      return false;
    }
    return (location.pathname != "/admins/profile") && (location.pathname != "/admins/security")
  }

  let admin_tab, security_tab;
  if (auth.role === "admin") {
    admin_tab = <StyledLink to="/admins" isActive={isAdminActive} activeClassName="selected"><IconStethscope /><span>Admins</span></StyledLink>
    security_tab = <StyledLink to="/admins/security" activeClassName="selected"><LockIcon style={{ color: "black" }} /><span>Security</span></StyledLink>
  } else {
    admin_tab = <p></p>
    security_tab = <p></p>
  }



  return (
    <Container>
      <TopContainer>
        <img src={Logo} alt="Scottish Rite Hospital" />
        <Label>Logged in as</Label>
        <Name>{auth.firstname} {auth.lastname}</Name>
      </TopContainer>
      <Nav>
        <StyledLink to="/dashboard" activeClassName="selected"><IconDashboard /><span>Dashboard</span></StyledLink>
        <StyledLink to="/scoliosis" activeClassName="selected"><IconSpine /><span>Scoliosis</span></StyledLink>
        <StyledLink to="/clubfoot" activeClassName="selected"><IconFootstep /><span>Club Foot</span></StyledLink>
        {admin_tab}
      </Nav>
      <LogoutContainer>
        <label>Settings</label>
        {security_tab}
        <StyledLink to="/admins/profile" activeClassName="selected"><PersonOutlineIcon style={{ color: "black" }} /><span>My Profile</span></StyledLink>
        <Logout onClick={() => logout()}><ExitToAppIcon /><span>Logout</span></Logout>
      </LogoutContainer>
    </Container>
  )
})
