import React from "react";
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import styled from "styled-components"
import PhoneMaskInput from "../PhoneMaskInput";

const useStyles = makeStyles(theme => ({
  textField: {
    width: '100%',
    margin: '10px 0',
  },
  gridItem: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  button: {
    padding: '10px 16px',
    width: 150
  },
}));

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`

const PatientForm = (props) => {
  const classes = useStyles();
  const [values, setValues] = React.useState({
    firstname: '',
    lastname: '',
    email: '',
    contact_number: '',
    sensor_id: '',
  });

  React.useEffect(() => {
    setValues({ ...props.user })
  }, [props.user])

  const handleChange = name => event => {
    setValues({ ...values, [name]: event.target.value });
  };

  const handleSave = () => {
    props.updateUser(values)
  }

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={6} className={classes.gridItem} style={{ paddingBottom: 0 }}>
          <TextField
            id="filled-firstname"
            className={classes.textField}
            label="First Name"
            value={values.firstname}
            onChange={handleChange('firstname')}
            margin="normal"
            variant="filled"
          />
        </Grid>
        <Grid item xs={6} style={{ paddingBottom: 0 }}>
          <TextField
            id="filled-lastname"
            className={classes.textField}
            label="Last Name"
            value={values.lastname}
            onChange={handleChange('lastname')}
            margin="normal"
            variant="filled"
          />
        </Grid>
        <Grid item xs={6} style={{ paddingBottom: 0 }}>
          <TextField
            disabled
            id="filled-email"
            className={classes.textField}
            label="Email"
            value={values.email}
            onChange={handleChange('email')}
            margin="normal"
            variant="filled"
          />
        </Grid>
        <Grid item xs={6} style={{ paddingBottom: 0 }}>
          <TextField
            disabled
            id="filled-contact"
            className={classes.textField}
            label="Phone Number"
            value={values.contact_number}
            onChange={handleChange('contact_number')}
            margin="normal"
            variant="filled"
            InputProps={{
              inputComponent: PhoneMaskInput,
            }}
          />
        </Grid>
        <Grid item xs={6} style={{ paddingBottom: 0 }}>
          <TextField
            disabled
            id="filled-sensor"
            className={classes.textField}
            label="Sensor #"
            value={values.sensor_id}
            onChange={handleChange('sensor_id')}
            margin="normal"
            variant="filled"
          />
        </Grid>
      </Grid>
      <ButtonContainer>
        <Button variant="contained" color="primary" className={classes.button} onClick={handleSave}>
          Save
        </Button>
      </ButtonContainer>
    </>
  )
}

export default PatientForm;