import React from "react"
import pathOr from "ramda/src/pathOr";
import { connect } from "react-redux";
import styled from "styled-components"
import Tabs from "../../components/tabs/Tabs";
import Tab from "../../components/tabs/Tab";
import TabPanel from "../../components/tabs/TabPanel";
import { palette } from "../../styles/variables";
import Graph from './Graph'
import PublishIcon from '@material-ui/icons/Publish';
import Button from '@material-ui/core/Button'
import ImportDialog from './ImportDialog';

const Container = styled.div`
  border-radius: 4px;
  border: solid 1px ${palette.border};
  background-color: white;
  position: relative;
`
const mapStateToProps = state => ({
  auth: pathOr({}, ["auth"], state),
});

const Graphs = (props) => {
  const [tabValue, setTabValue] = React.useState(0);
  const [ShowImportDialog, setShowImportDialog] = React.useState(false);

  function handleTabChange(event, newValue) {
    setTabValue(newValue);
  }


  return (
    <Container>
      {(props.auth.role === "admin") && (window.location.href.includes("clubfoot") && props.userId == 206) && <Button style={{ margin: 10 }} onClick={() => setShowImportDialog(true)}><span style={{ marginRight: "5px" }}>Import Test Data</span><PublishIcon /></Button>}
      <Tabs value={tabValue} onChange={handleTabChange} aria-label="Parent/Patient Info Box">
        <Tab label="Daily" />
        <Tab label="Weekly" />
        <Tab label="Monthly" />
      </Tabs>
      <TabPanel value={tabValue} index={0}>
        <Graph scale="daily" data={props.daily} users={props.users} userId={props.userId} />
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <Graph scale="weekly" data={props.weekly} users={props.users} userId={props.userId} />
      </TabPanel>
      <TabPanel value={tabValue} index={2}>
        <Graph scale="monthly" data={props.monthly} users={props.users} userId={props.userId} />
      </TabPanel>
      <ImportDialog open={ShowImportDialog} onClose={() => setShowImportDialog(false)} />
    </Container>
  )
}

export default connect(mapStateToProps, null)(Graphs)