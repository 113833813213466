import React, { useEffect } from "react";
import pathOr from "ramda/src/pathOr";
import { connect } from "react-redux";
import styled from "styled-components";
import { Route, Switch, Redirect } from "react-router-dom";
import { useIdleTimer } from 'react-idle-timer';
import Dashboard from "../dashboard";
import Scoliosis from "../scoliosis";
import ScoliosisPatient from "../scoliosis/patient";
import ClubFoot from "../club-foot";
import ClubFootPatient from "../club-foot/patient";
import Doctors from "../doctors";
import MyProfile from "../doctors/profile";
import AdminProfile from "../doctors/profile/adminProfile";
import Security from "../security";
import Sidebar from "./Sidebar";
import { palette } from "../../styles/variables";
import { getAdmins } from "../../actions/admin";
import { getProfile } from "../../actions/auth";
import { getDashboardUsers } from "../../actions/dashboard";
import { getClubFootUsers, getClubFootUserDetails } from "../../actions/clubFoot";
import { getScoliosisUsers, getScoliosisUserDetails } from "../../actions/scoliosis";
import { getPasswordConfig } from "../../actions/passwordConfig";
import { logout } from "../../actions/auth";
import IdleDialog from "./IdleDialog";

const Container = styled.div`
  display: flex;
  min-height: 100vh;
`;

const Main = styled.div`
  flex: 1;
  padding: 32px;
  margin-left: 255px;
  background-color: ${palette.background};
`;

const mapStateToProps = state => ({
  passwordConfig: pathOr({}, ["passwordConfig"], state)
});

const mapDispatchToProps = dispatch => ({
  getAdmins: () => dispatch(getAdmins()),
  getProfile: () => dispatch(getProfile()),
  getDashboardUsers: () => dispatch(getDashboardUsers()),
  getClubFootUserDetails: (id) => dispatch(getClubFootUserDetails(id)),
  getScoliosisUserDetails: (id) => dispatch(getScoliosisUserDetails(id)),
  getPasswordConfig: () => dispatch(getPasswordConfig()),
  logout: () => dispatch(logout())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(({ getAdmins, getProfile, getDashboardUsers, getClubFootUserDetails, getScoliosisUserDetails, getPasswordConfig, passwordConfig, logout }) => {
  useEffect(() => {
    getAdmins();
    getProfile();
    getDashboardUsers();
    getPasswordConfig();
  }, [
    getAdmins,
    getProfile,
    getDashboardUsers,
    getPasswordConfig
  ])

  const [showDialog, setShowDialog] = React.useState(false);
  const [userIsIdle, setUserIsIdle] = React.useState(false);

  const handleOnIdle = event => {
    if (userIsIdle) {
      logout()
    } else {
      setShowDialog(true)
      reset()
      setUserIsIdle(true)
    }
  }

  const handleOnActive = event => {
    // not used
  }

  const handleOnAction = (e) => {
    if (userIsIdle) {
      setUserIsIdle(false)
    }
  }

  const { getRemainingTime, getLastActiveTime, reset } = useIdleTimer({
    timeout: 1000 * 60 * 5,
    onIdle: handleOnIdle,
    onActive: handleOnActive,
    onAction: handleOnAction,
    debounce: 500
  })

  return (
    <Container>
      <Sidebar />
      <Main>
        <Switch>
          <Route exact path="/dashboard" component={Dashboard} />
          <Route exact path="/scoliosis" component={Scoliosis} />
          <Route exact path="/scoliosis/:id"
            render={(props) => <ScoliosisPatient {...props} getScoliosisUserDetails={getScoliosisUserDetails} />} />
          <Route exact path="/clubfoot" component={ClubFoot} />
          <Route exact path="/clubfoot/:id" 
            render={(props) => <ClubFootPatient {...props} getClubFootUserDetails={getClubFootUserDetails} />} />
          <Route exact path="/admins" component={Doctors} />
          <Route exact path="/admins/profile" component={MyProfile} />
          <Route exact path="/admins/security" component={Security} />
          <Route exact path="/admins/:id" component={AdminProfile} />
          <Redirect exact path="/" to="/dashboard" />
        </Switch>
      </Main>
      <IdleDialog open={showDialog} onClose={() => setShowDialog(false)} />
    </Container>
  );
});
