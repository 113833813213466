import initState from "./initialState";
import { GET_ADMINS_SUCCEEDED, GET_ADMINS_FAILED, GET_ADMIN_SUCCEEDED, GET_ADMIN_FAILED} from "../actions/actionTypes";
import { hideLoading } from "../utils/helpers";

const reducer = (state = initState.admin, action) => {
  switch (action.type) {
    case GET_ADMINS_SUCCEEDED: 
      state = {
        ...state,
        loading: false,
        admins: action.data
      }
      return state;
    case GET_ADMINS_FAILED:
      state = {
        ...state,
        loading: false
      }
      return state;
    case GET_ADMIN_SUCCEEDED:
      state = {
        ...state,
        admin: action.data
      }
      hideLoading();
    case GET_ADMIN_FAILED:
        hideLoading();
      return state;
    default:
      return state;
  }
};

export default reducer;
