import { GET_DASHBOARD, EXPORT_USERS, IMPORT_CLUBFOOT_PATIENT_DATA, IMPORT_SCOLIOSIS_PATIENT_DATA } from "./actionTypes";

export function getDashboardUsers() {
  return { type: GET_DASHBOARD, payload: {} }
}

export function exportUsers() {
  return { type: EXPORT_USERS, payload: {} }
}

export function importClubFootPatientData(file) {
  return { type: IMPORT_CLUBFOOT_PATIENT_DATA, payload: { file } }
}

export function importScoliosisPatientData(file) {
  return { type: IMPORT_SCOLIOSIS_PATIENT_DATA, payload: { file } }
}