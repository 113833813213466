import React from "react"
import { connect } from "react-redux";
import pathOr from "ramda/src/pathOr";
import styled from "styled-components"
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FilledInput from '@material-ui/core/FilledInput';
import Button from '@material-ui/core/Button';
import { palette } from "../../../styles/variables";
import { updateProfile } from '../../../actions/auth.js'
import ResetPasswordDialog from "../ResetPasswordDialog"

const useStyles = makeStyles(theme => ({
  textField: {
    width: '100%',
    margin: 0,
  },
  formControl: {
    marginTop: 16,
    width: '100%',
  },
  gridItem: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  label: {
    color: 'rgba(0, 0, 0, 0.87)',
  },
  button: {
    padding: '10px 16px',
    width: 150
  },
  defaultButton: {
    padding: '10px 16px',
    width: 150,
    color: palette.gray
  },
  checkbox: {
    width: 160,
  },
  resetPasswordButton: {
    padding: '10px 16px',
    width: 200,
    color: palette.gray,
    marginRight: 10
  },
}));

const CheckboxContainer = styled.div`
  display: flex;
`

const Container = styled.div`
  border-radius: 4px;
  border: solid 1px ${palette.border};
  background: white;
`

const Header = styled.div`
  padding: 24px;
  border-bottom: 1px solid ${palette.border};
`

const H1 = styled.h1`
  font-size: 20px;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: 0.15px;
  color: rgba(0, 0, 0, 0.87);
`

const H2 = styled.h2`
  font-size: 14px;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: 0.1px;
  color: rgba(0, 0, 0, 0.87);
`

const Main = styled.div`
  padding: 33px 24px;
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`
const mapStateToProps = state => ({
  profile: pathOr({}, ["auth"], state)
});

const mapDispatchToProps = dispatch => {
  return {
    updateProfile: (profile) => dispatch(updateProfile(profile)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)((props) => {
  const classes = useStyles();
  const [values, setValues] = React.useState({
    firstname: '',
    lastname: '',
    email: '',
    status: true,
    speciality: '',
    id: '',
  });

  const [showDialog, setShowDialog] = React.useState(false);
  const [showResetPassword, setShowResetPassword] = React.useState(false);

  React.useEffect(() => {
    setValues({ ...props.profile })
  }, [props.profile])

  const handleChange = name => event => {
    let letter = ''
    if (name == "speciality") {
      if (values.speciality === "B") {
        letter = event.target.value === 'S' ? 'C' : 'S'
      } else if ((values.speciality === 'S' && event.target.value === 'C') || (values.speciality === 'C' && event.target.value === 'S')) {
        letter = 'B'
      } else {
        letter = event.target.value
      }
      setValues({ ...values, [name]: letter });
    } else {
      setValues({ ...values, [name]: event.target.value });
    }
  };

  const handleSave = event => {
    let profile = {
      firstname: values.firstname,
      lastname: values.lastname,
      email: values.email,
      status: values.status,
      speciality: values.speciality,
    }
    props.updateProfile(profile)
  }

  if (props.profile) {
    return (
      <Container>
        <Header>
          <H1>Your Profile</H1>
        </Header>
        <Main>
          <H2>Profile Info</H2>
          <Grid container spacing={3}>
            <Grid item xs={6} className={classes.gridItem}>
              <TextField
                id="filled-firstname"
                className={classes.textField}
                label="First Name"
                value={values.firstname}
                onChange={handleChange('firstname')}
                margin="normal"
                variant="filled"
              />
            </Grid>
            <Grid item xs={6} className={classes.gridItem}>
              <TextField
                id="filled-lastname"
                className={classes.textField}
                label="Last Name"
                value={values.lastname}
                onChange={handleChange('lastname')}
                margin="normal"
                variant="filled"
              />
            </Grid>
            <Grid item xs={6} className={classes.gridItem}>
              <TextField
                id="filled-email"
                className={classes.textField}
                label="Email Address"
                value={values.email}
                onChange={handleChange('email')}
                margin="normal"
                variant="filled"
              />
            </Grid>
            <Grid item xs={6} className={classes.gridItem}>
              <FormControl variant="filled" className={classes.textField}>
                <InputLabel htmlFor="filled-age-simple">Status</InputLabel>
                <Select
                  value={values.status}
                  onChange={handleChange('status')}
                  input={<FilledInput name="age" id="filled-age-simple" />}
                >
                  <MenuItem value={true}>Active</MenuItem>
                  <MenuItem value={false}>Inactive</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6} className={classes.gridItem}>
              <FormControl component="fieldset" className={classes.formControl}>
                <FormLabel component="legend" className={classes.label}>Specialty</FormLabel>
                <CheckboxContainer>
                  <FormControlLabel
                    className={classes.checkbox}
                    control={
                      <Checkbox
                        checked={values.speciality === 'C' || values.speciality === 'B'}
                        onChange={handleChange('speciality')}
                        value="C"
                        color="primary"
                      />
                    }
                    label="Club Foot"
                  />
                  <FormControlLabel
                    className={classes.checkbox}
                    control={
                      <Checkbox
                        checked={values.speciality === 'S' || values.speciality === 'B'}
                        onChange={handleChange('speciality')}
                        value="S"
                        color="primary"
                      />
                    }
                    label="Scoliosis"
                  />
                </CheckboxContainer>
              </FormControl>
            </Grid>
          </Grid>
          <ButtonContainer>
            <Button className={classes.resetPasswordButton} onClick={() => setShowResetPassword(true)}>Reset Password</Button>
            <Button variant="contained" color="primary" className={classes.button} onClick={handleSave}>
              Save
            </Button>
          </ButtonContainer>
        </Main>
        <ResetPasswordDialog open={showResetPassword} onClose={() => setShowResetPassword(false)} id={values.id} />
      </Container>
    )
  }
})