import React from "react"
import pathOr from "ramda/src/pathOr";
import { connect } from "react-redux";
import PeopleIcon from '@material-ui/icons/People';
import IconBox from "../../components/IconBox";
import EnhancedTable from "./Table"
import { exportUsers } from '../../actions/dashboard.js'

const mapStateToProps = state => ({
  dashboard: pathOr({}, ["dashboard"], state),
  scoliosis: pathOr({}, ["dashboard", "scoliosis"], state),
  clubFoot: pathOr({}, ["dashboard", "clubFoot"], state)
});

const mapDispatchToProps = dispatch => {
  return {
    exportUsers: () => dispatch(exportUsers()),
  }
}

const getCount = (patientType, dashboard, scoliosis, clubFoot) => {
  let results = []
  if (patientType === 'scoliosis') {
    results = scoliosis.results || []
  } else if (patientType === 'clubfoot') {
    results = clubFoot.results || []
  } else {
    results = dashboard.results || []
  }
  return results.length
}

export default connect(mapStateToProps, mapDispatchToProps)(({ dashboard, scoliosis, clubFoot, exportUsers }) => {

  const [patientType, setPatientType] = React.useState('all');
  return (
    <>
      <div>
        <IconBox
          IconComponent={<PeopleIcon />}
          value={getCount(patientType, dashboard, scoliosis, clubFoot) || 0}
          label="Patients"
        />
        <EnhancedTable
          rows={dashboard.results ? dashboard.results.slice(0, -1) : []}
          loading={dashboard.loading}
          exportUsers={exportUsers}
          patientType={patientType}
          setPatientType={setPatientType}
        />
      </div>
    </>
  )
})
