import React from 'react';
import { connect } from "react-redux";
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { addAdmin } from '../../actions/admin.js'

const useStyles = makeStyles(theme => ({
  textField: {
    width: 250,
  },
  formControl: {
    marginTop: 16,
  },
  content: {
    overflow: 'hidden',
  }
}));

const mapDispatchToProps = dispatch => {
  return {
    addAdmin: (admin) => dispatch(addAdmin(admin)),
  }
}

export default connect(null, mapDispatchToProps)((props) => {
  const classes = useStyles();
  const [values, setValues] = React.useState({
    firstname: '',
    lastname: '',
    email: '',
    speciality: '',
    password: '',
  });

  const handleSubmit = event => {
    props.operation()
    props.onClose();
  }

  return (
    <div>
      <Dialog open={props.open} onClose={props.onClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">{props.title}</DialogTitle>
        <DialogContent className={classes.content} style={{ textAlign: 'center' }}>
          <span style={{ paddingLeft: 50, paddingRight: 50 }}>Are you sure?</span>
        </DialogContent>
        <DialogActions style={{ justifyContent: 'center' }}>
          <Button onClick={props.onClose}>
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="secondary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
})
