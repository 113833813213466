import React from 'react';
import { connect } from "react-redux";
import pathOr from "ramda/src/pathOr";
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import InputAdornment from "@material-ui/core/InputAdornment";
import FilledInput from "@material-ui/core/FilledInput";
import InputLabel from "@material-ui/core/InputLabel";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Tooltip from '@material-ui/core/Tooltip';
import { RadioGroup, Radio } from "@material-ui/core";
import { addAdmin } from '../../actions/admin.js'
import { validatePassword } from "../../utils/helpers";

const CheckboxContainer = styled.div`
  display: flex;
`

const useStyles = makeStyles(theme => ({
  textField: {
    width: 250,
  },
  radioFormControl: {
    marginTop: 25 + 30,
  },
  formControl: {
    marginTop: 16 + 30,
  },
  content: {
    overflow: 'hidden',
  },
  passwordFormControl: {
    marginTop: 16,
  }
}));

const mapDispatchToProps = dispatch => {
  return {
    addAdmin: (admin) => dispatch(addAdmin(admin)),
  }
}

const mapStateToProps = state => ({
  passwordConfig: pathOr({}, ["passwordConfig"], state)
});

export default connect(mapStateToProps, mapDispatchToProps)((props) => {
  const classes = useStyles();
  const [values, setValues] = React.useState({
    firstname: '',
    lastname: '',
    email: '',
    password: '',
    speciality: 'S',
    role: "viewer",
    passwordError: false,
  });

  const handleChange = name => event => {
    let letter = ''
    if (name === "speciality") {
      if (values.speciality === "B") {
        letter = event.target.value === 'S' ? 'C' : 'S'
      } else if ((values.speciality === 'S' && event.target.value === 'C') || (values.speciality === 'C' && event.target.value === 'S')) {
        letter = 'B'
      } else {
        letter = event.target.value
      }
      setValues({ ...values, [name]: letter });
    } else {
      setValues({ ...values, [name]: event.target.value });
    }
  };

  const handleSubmit = event => {
    if (validatePassword(props.passwordConfig, values.password)) {
      setValues({ ...values, passwordError: false })
      props.addAdmin(values)
      setValues({ ...values, firstname: '', lastname: '', email: '', password: '', role: "viewer" })
      props.onClose();
    } else {
      setValues({ ...values, passwordError: true })
    }
  }

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  const password_length_message = "Password must be " + props.passwordConfig.minlength + "-" + props.passwordConfig.maxlength + " characters."
  let password_requirements_message;
  if (props.passwordConfig.isspcharrequired && props.passwordConfig.isnumrequired && props.passwordConfig.iscapletterrequired) {
    password_requirements_message = "You must also include at least one capital letter, number, and special character."
  } else if (!props.passwordConfig.isspcharrequired && props.passwordConfig.isnumrequired && props.passwordConfig.iscapletterrequired) {
    password_requirements_message = "You must also include at least one capital letter and number."
  } else if (props.passwordConfig.isspcharrequired && !props.passwordConfig.isnumrequired && props.passwordConfig.iscapletterrequired) {
    password_requirements_message = "You must also include at least one capital letter and special character."
  } else if (props.passwordConfig.isspcharrequired && props.passwordConfig.isnumrequired && !props.passwordConfig.iscapletterrequired) {
    password_requirements_message = "You must also include at least one number and special character."
  } else if (!props.passwordConfig.isspcharrequired && !props.passwordConfig.isnumrequired && props.passwordConfig.iscapletterrequired) {
    password_requirements_message = "You must also include at least one capital letter."
  } else if (props.passwordConfig.isspcharrequired && !props.passwordConfig.isnumrequired && !props.passwordConfig.iscapletterrequired) {
    password_requirements_message = "You must also include at least one special character."
  } else if (!props.passwordConfig.isspcharrequired && props.passwordConfig.isnumrequired && !props.passwordConfig.iscapletterrequired) {
    password_requirements_message = "You must also include at least one number."
  } else {
    password_requirements_message = " "
  }

  return (
    <div>
      <input style={{ opacity: 0, position: 'absolute' }} />
      <input type="password" style={{ opacity: 0, position: 'absolute' }} />
      <Dialog open={props.open} onClose={props.onClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Add a New Admin</DialogTitle>
        <DialogContent className={classes.content}>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <TextField
                id="filled-firstname"
                className={classes.textField}
                label="First Name"
                value={values.firstname}
                onChange={handleChange('firstname')}
                margin="normal"
                variant="filled"
              />
              <TextField
                id="filled-email"
                className={classes.textField}
                label="Email Address"
                value={values.email}
                onChange={handleChange('email')}
                margin="normal"
                variant="filled"
              />
              <FormControl component="fieldset" className={classes.formControl}>
                <FormLabel component="legend">Specialty</FormLabel>
                <CheckboxContainer>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.speciality === 'C' || values.speciality === 'B'}
                        onChange={handleChange('speciality')}
                        value="C"
                        color="primary"
                      />
                    }
                    label="Club Foot"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.speciality === 'S' || values.speciality === 'B'}
                        onChange={handleChange('speciality')}
                        value="S"
                        color="primary"
                      />
                    }
                    label="Scoliosis"
                  />
                </CheckboxContainer>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="filled-lastname"
                className={classes.textField}
                label="Last Name"
                value={values.lastname}
                onChange={handleChange('lastname')}
                margin="normal"
                variant="filled"
              />
              {/* <TextField
                id="filled-password"
                className={classes.textField}
                label="Password"
                value={values.password}
                onChange={handleChange('password')}
                margin="normal"
                error={values.passwordError}
                variant="filled"
                helperText={values.passwordError ? "Must be at least 9 characters" : ''}
              /> */}

              <Tooltip title={password_length_message + " " + password_requirements_message} placement="bottom-start" open={values.passwordError}>
                <FormControl
                  className={classes.passwordFormControl}
                  variant="filled"
                // error={isSubmitted && errors.password}
                // error={isSubmitted && errors.password}
                >
                  <InputLabel htmlFor="filled-password">Password</InputLabel>
                  <FilledInput
                    id="filled-password"
                    className={classes.textField}
                    label="Password"
                    type={values.showPassword ? "text" : "password"}
                    value={values.password}
                    onChange={handleChange("password")}
                    error={values.passwordError}
                    autoComplete="password"
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {values.showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </Tooltip>
              <FormControl component="fieldset" className={classes.radioFormControl}>
                <FormLabel component="legend" className={classes.label}>Role</FormLabel>
                <RadioGroup row value={values.role} onChange={handleChange('role')}>
                  <FormControlLabel value="viewer" control={<Radio />} label="Viewer" />
                  <FormControlLabel value="admin" control={<Radio />} label="Administrator" />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>

        </DialogContent>
        <DialogActions>
          <Button onClick={props.onClose}>
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="secondary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
})
