import React from "react";
import { withStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';

export default withStyles(theme => ({
  root: {
    textTransform: 'none',
    minWidth: 72,
    fontSize: '14px',
    lineHeight: 1.5,
    marginRight: theme.spacing(4),
    padding: '26px 7px',
    color: 'rgba(0, 0, 0, 0.87)',
    opacity: 0.4,
    '&:hover': {
      opacity: 1,
    },
    '&$selected': {
      fontWeight: theme.typography.fontWeightMedium,
    },
    '&:focus': {
    },
  },
  selected: {},
}))(props => <Tab disableRipple {...props} />);
  