import initState from "./initialState";
import { GET_DASHBOARD_SUCCEEDED, GET_DASHBOARD_FAILED } from "../actions/actionTypes";

const reducer = (state = initState.dashboard, action) => {
  console.log('action', action.type, action)
  switch (action.type) {
    case GET_DASHBOARD_SUCCEEDED:
      state = {
        results: action.data.results,
        scoliosis: {
          results: action.data.results.filter(x => x.patient_type === "scoliosis")
        },
        clubfoot: {
          results: action.data.results.filter(x => x.patient_type === "clubfoot")
        },
        loading: false
      }
      return state;
    case GET_DASHBOARD_FAILED:
      state = {
        ...state,
        loading: false
      }
      return state
    default:
      return state;
  }
};

export default reducer;
