import React, { useEffect } from "react";
import pathOr from "ramda/src/pathOr";
import { connect } from "react-redux";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import InputLabel from "@material-ui/core/InputLabel";
import FilledInput from "@material-ui/core/FilledInput";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Button from "@material-ui/core/Button";
import { palette } from "../../styles/variables";
import FormContainer from "./FormContainer";
import { loginV2, validate2FA } from "../../actions/auth";
import { validateEmail } from "../../utils/helpers";
import Iframe from 'react-iframe'
import DuoWebSDK from 'duo_web_sdk';

const StyledLink = styled(Link)`
  font-size: 14px;
  font-weight: 500;
  line-height: 1.14;
  letter-spacing: 1.25px;
  color: ${palette.gray};
  margin-top: 32px;
  text-transform: uppercase;
  display: block;
`;

const ErrorMessage = styled.p`
  text-align: left;
  margin-bottom: 20px;
  color: ${palette.red};
`

const useStyles = makeStyles(theme => ({
  formControl: {
    width: "100%",
    marginBottom: "40px"
  },
  button: {
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: 1.14,
    letterSpacing: "1.25px",
    padding: "16px",
    width: "100%"
  }
}));

const mapStateToProps = state => {
  const loading = pathOr(false, ["auth", "loading"], state);
  const error = pathOr(false, ["auth", "error"], state);
  const tempToken = pathOr("", ["auth", "tempToken"], state);
  const sigRequest = pathOr(false, ["auth", "sigRequest"], state);
  const host = pathOr("", ["auth", "host"], state);
  const loginSucceeded = pathOr("", ["auth", "loginSucceeded"], state);

  return {
    loading,
    error,
    tempToken,
    sigRequest,
    host,
    loginSucceeded
  };
};

const mapDispatchToProps = dispatch => ({
  loginV2: (email, password) => dispatch(loginV2(email, password)),
  validate2FA: (tempToken, sigResponse) => dispatch(validate2FA(tempToken, sigResponse))
});

const Login = ({ loginV2, validate2FA, loading, error, tempToken, sigRequest, host, loginSucceeded }) => {
  const classes = useStyles();
  const [isSubmitted, setIsSubmitted] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');
  const [errors, setErrors] = React.useState({
    email: false,
    password: false,
  });
  const [values, setValues] = React.useState({
    email: "",
    password: "",
    showPassword: false
  });

  useEffect(() => {
    setErrorMessage(error);
  }, [error]);

  useEffect(() => {
    if (loginSucceeded) {
      initDuoFrame();
    }
  }, [loginSucceeded]);

  const handleChange = name => event => {
    errorMessage && setErrorMessage('');
    if (name === "email") {
      setErrors({ ...errors, email: !validateEmail(event.target.value) });
    }
    if (name === "password") {
      setErrors({ ...errors, password: event.target.value.length < 6 });
    }
    setValues({ ...values, [name]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  const handleSignIn = () => {
    setIsSubmitted(true);
    if (!errors.email && !errors.password) {
      loginV2(values.email, values.password);
    }
  };

  const handleValidate2FA = (form) => {
    let sigResponse = form.sig_response.value
    validate2FA(tempToken, sigResponse)
  }

  const initDuoFrame = () => {
    DuoWebSDK.init({
      iframe: "duo-frame",
      host: host,
      sig_request: sigRequest,
      submit_callback: handleValidate2FA,
    });
  }

  const randomId = Math.floor((Math.random() * 10000) + 1);

  return (
    <FormContainer title="Brace Rite Sign In">
      {!loginSucceeded ?
        <div>
          <form noValidate onSubmit={handleSignIn}>
            <input style={{ opacity: 0, position: 'absolute' }} />
            <input type="password" style={{ opacity: 0, position: 'absolute' }} />
            {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
            <FormControl
              className={classes.formControl}
              variant="filled"
              error={isSubmitted && errors.email}
            >
              <InputLabel htmlFor="email">Email Address</InputLabel>
              <FilledInput
                id="email"
                type="email"
                autoComplete="email"
                fullWidth
                value={values.email}
                onChange={handleChange("email")}
              />
              {isSubmitted && errors.email && (
                <FormHelperText id="email-error-text">
                  Please enter a correct email format.
                </FormHelperText>
              )}
            </FormControl>

            <FormControl
              className={classes.formControl}
              variant="filled"
              error={isSubmitted && errors.password}
            >
              <InputLabel htmlFor="adornment-password">Password</InputLabel>
              <FilledInput
                id="adornment-password"
                type={values.showPassword ? "text" : "password"}
                value={values.password}
                onChange={handleChange("password")}
                autoComplete="password"
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {values.showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
              />
              {isSubmitted && errors.password && (
                <FormHelperText id="password-error-text">
                  Incorrect Password
                </FormHelperText>
              )}
            </FormControl>

            <Button
              variant="contained"
              color="primary"
              type="submit"
              className={classes.button}
              onClick={handleSignIn}
              disabled={loading}
            >
              sign in
            </Button>
          </form>
          <StyledLink to="/forgot-password">Forgot password?</StyledLink>
        </div> :
        <iframe id="duo-frame" style={{ width: '540px', height: '450px' }} />
      }
    </FormContainer>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
