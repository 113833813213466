import React from "react"
import styled from "styled-components"
import IconBox from "../../components/IconBox";
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import PollIcon from '@material-ui/icons/Poll';
import EventNoteIcon from '@material-ui/icons/EventNote';
import DateBox from "../DateBox";
import find from 'lodash/find'

const Container = styled.div`
  display: flex;
  justify-content: space-between;
`

const IconsContainer = styled.div`
  > div {
    display: inline-block;
    margin-right: 32px;
    &:last-of-type {
      margin-right: 0;
    }
  }
`

const PatientStatusBox = (props) => {
  let user = props.user
  if (!user) {
    user = find(props.users.results, ["user_id", parseInt(props.userId)]) || {}
    user.avg_for_month = user.avg_hrs_per_day || 0
  }
  return (
    <>
      <Container>
        <IconsContainer>
          <IconBox
            IconComponent={<AssignmentTurnedInIcon />}
            value={`${Math.round(user.avg_for_month / user.prescribed_hours * 100)}%`}
            label="Goals Completed"
          />
          <IconBox
            IconComponent={<EventNoteIcon />}
            value={`${Math.round(user.avg_for_month)} hrs`}
            label="Avg for Month"
          />
          <IconBox
            IconComponent={<PollIcon />}
            value={`${user.prescribed_hours} hrs`}
            label="Prescribed Hours"
          />
        </IconsContainer>
        <div>
          <DateBox />
        </div>
      </Container>
    </>
  )
}

export default PatientStatusBox