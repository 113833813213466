import React from "react"
import { connect } from "react-redux";
import pathOr from "ramda/src/pathOr";
import styled from "styled-components"
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { palette } from "../../styles/variables";
import { updatePasswordConfig } from '../../actions/passwordConfig.js'
import { FormGroup } from "@material-ui/core";
import ConfirmationDialog from "./ConfirmationDialog"

const useStyles = makeStyles(theme => ({
    textField: {
        width: '100%',
        margin: 0,
    },
    formControl: {
        marginTop: 16,
        width: '100%',
    },
    gridItem: {
        paddingTop: 0,
        paddingBottom: 0,
    },
    label: {
        color: 'rgba(0, 0, 0, 0.87)',
    },
    button: {
        padding: '10px 16px',
        width: 150
    },
    defaultButton: {
        padding: '10px 16px',
        width: 150,
        color: palette.gray
    },
    checkbox: {
        width: 300,
    },
}));

const CheckboxContainer = styled.p`
  display: flex;
  margin-top: 15px;
  font-size: 10px;
  color: gray;
`

const Container = styled.div`
  border-radius: 4px;
  border: solid 1px ${palette.border};
  background: white;
`

const Header = styled.div`
  padding: 24px;
  border-bottom: 1px solid ${palette.border};
`

const H1 = styled.h1`
  font-size: 20px;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: 0.15px;
  color: rgba(0, 0, 0, 0.87);
`

const H2 = styled.h2`
  font-size: 14px;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: 0.1px;
  color: rgba(0, 0, 0, 0.87);
`
const H3 = styled.h3`
  font-size: 12px;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: 0.1px;
  color: gray;
`
const CheckboxDetail = styled.p`
  font-size: 12px;
  line-height: 1.5;
  letter-spacing: 0.1px;
  color: black;
`

const InputDetail = styled.p`
  font-size: 12px;
  line-height: 1.5;
  letter-spacing: 0.1px;
  color: black;
  margin-left: 5px;
  margin-top: 10px;
  margin-botttom: 20px;
`

const Main = styled.div`
  padding: 33px 24px;
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`

const mapStateToProps = state => ({
    auth: pathOr({}, ["auth"], state),
    passwordConfig: pathOr({}, ["passwordConfig"], state)
});

const mapDispatchToProps = dispatch => {
    return {
        updatePasswordConfig: (passwordConfig) => dispatch(updatePasswordConfig(passwordConfig)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)((props) => {
    const classes = useStyles();
    const [showDialog, setShowDialog] = React.useState(false);
    const [values, setValues] = React.useState({
        minlength: null,
        maxlength: null,
        isspcharrequired: false,
        isnumrequired: false,
        iscapletterrequired: false,
        numfailedattempts: null,
        numrememberedpasswords: null,
        timeout: null
    });

    React.useEffect(() => {
        setValues({ ...props.passwordConfig })
    }, [props.passwordConfig])

    const handleChange = name => event => {
        if (name === "isspcharrequired") {
            setValues({ ...values, [name]: values.isspcharrequired === true ? false : true });
        } else if (name === "isnumrequired") {
            setValues({ ...values, [name]: values.isnumrequired === true ? false : true });
        } else if (name === "iscapletterrequired") {
            setValues({ ...values, [name]: values.iscapletterrequired === true ? false : true });
        } else {
            setValues({ ...values, [name]: Number(event.target.value) });
        }
    };

    const handleSave = event => {
        let passwordConfig = {
            minlength: values.minlength,
            maxlength: values.maxlength,
            isspcharrequired: values.isspcharrequired,
            isnumrequired: values.isnumrequired,
            iscapletterrequired: values.iscapletterrequired,
            numfailedattempts: values.numfailedattempts,
            numrememberedpasswords: values.numrememberedpasswords,
            timeout: values.timeout
        }
        props.updatePasswordConfig(passwordConfig)
    }

    if (props.auth.role === "admin") {
        return (
            <Container>
                <Header>
                    <H1>Security Settings</H1>
                </Header>
                <Main>
                    <Grid container spacing={3}>
                        <Grid item xs={6} className={classes.gridItem}>
                            <H2>Password Requirements:</H2>
                            <H3>Password Length</H3>
                            <Grid container spacing={3}>
                                <Grid item xs={6} className={classes.gridItem}>
                                    <TextField
                                        id="min-length"
                                        className={classes.textField}
                                        label="Password Min Length"
                                        value={values.minlength}
                                        onChange={handleChange('minlength')}
                                        margin="normal"
                                        variant="filled"
                                        type="number"
                                    />
                                </Grid>
                                <Grid item xs={6} className={classes.gridItem}>
                                    <TextField
                                        id="max-length"
                                        className={classes.textField}
                                        label="Password Max Length"
                                        value={values.maxlength}
                                        onChange={handleChange('maxlength')}
                                        margin="normal"
                                        variant="filled"
                                        type="number"
                                    />
                                </Grid>
                            </Grid>
                            <FormGroup column>
                                <CheckboxContainer>
                                    <FormControlLabel
                                        className={classes.checkbox}
                                        label="Require Special Character"
                                        control={
                                            <Checkbox
                                                checked={values.isspcharrequired}
                                                onChange={handleChange('isspcharrequired')}
                                                value={true}
                                                color="primary"
                                            />
                                        }
                                    />
                                </CheckboxContainer>
                                <CheckboxDetail>Strengthens Brace Rite passwords by requiring at least 1 special character in the password. e.g. ~!@#$%^&amp;*()</CheckboxDetail>
                                <CheckboxContainer>
                                    <FormControlLabel
                                        className={classes.checkbox}
                                        label="Require Number"
                                        control={
                                            <Checkbox
                                                checked={values.isnumrequired}
                                                onChange={handleChange('isnumrequired')}
                                                value={true}
                                                color="primary"
                                            />
                                        }
                                    />
                                </CheckboxContainer>
                                <CheckboxDetail>Strengthens Brace Rite passwords by requiring at least 1 number in the password.</CheckboxDetail>
                                <CheckboxContainer>
                                    <FormControlLabel
                                        className={classes.checkbox}
                                        label="Require Capital Letter"
                                        control={
                                            <Checkbox
                                                checked={values.iscapletterrequired}
                                                onChange={handleChange('iscapletterrequired')}
                                                value={true}
                                                color="primary"
                                            />
                                        }
                                    />
                                </CheckboxContainer>
                                <CheckboxDetail>Strengthens Brace Rite passwords by requiring at least 1 capital letter in the password.</CheckboxDetail>
                            </FormGroup>
                            <br />
                            <br />
                            {/* <H2>Idle Timeout:</H2>
                            <H3>Inactivity Time Length</H3>
                            <Grid container spacing={3}>
                                <Grid item xs={6} className={classes.gridItem}>
                                    <TextField
                                        id="timeout"
                                        className={classes.textField}
                                        label="Enter Duration in Minutes"
                                        value={values.timeout}
                                        onChange={handleChange('timeout')}
                                        margin="normal"
                                        variant="filled"
                                        type="number"
                                    />
                                </Grid>
                                <InputDetail>Length of time before a user is automatically logged out from their session due to inactivity.</InputDetail>
                            </Grid> */}
                        </Grid>
                        <Grid item xs={6} className={classes.gridItem}>
                            <H2>Account Lockout After Failed Logins (Coming soon!)</H2>
                            <TextField
                                disabled
                                id="numFailedAttempts"
                                className={classes.textField}
                                label="Number of Login Attempts Before Lockout"
                                value={values.numfailedattempts}
                                onChange={handleChange('numfailedattempts')}
                                margin="normal"
                                variant="filled"
                                type="number"
                            />
                            <InputDetail>Strengthens Brace Rite security by locking an account after the number of failed attempts specified. Default value is 10. Set value to 0 to disable this feature.</InputDetail>
                            <H2>Password History (Coming soon!)</H2>
                            <TextField
                                disabled
                                id="numrememberedpasswords"
                                className={classes.textField}
                                label="Number of Passwords to be Remembered"
                                value={values.numrememberedpasswords}
                                onChange={handleChange('numrememberedpasswords')}
                                margin="normal"
                                variant="filled"
                                type="number"
                            />
                            <InputDetail>How many historical passwords are remembered by Brace Rite? Brace Rite will compare passwords to the past passwords and not allow the same password to be re-used. Enter a number. 0 is the default and will disable this feature.</InputDetail>
                        </Grid>

                    </Grid>
                    <ButtonContainer>
                        <Button variant="contained" color="primary" className={classes.button} onClick={() => setShowDialog(true)}>
                            Save
                        </Button>
                    </ButtonContainer>
                </Main>
                <ConfirmationDialog open={showDialog} onClose={() => setShowDialog(false)} onSave={handleSave} />
            </Container>
        )
    } else {
        return (
            <h1>ERROR: Status Code 403 - You are not authorized to view this page.</h1>
        )
    }
})