import React from 'react';
import { connect } from "react-redux";
import pathOr from "ramda/src/pathOr";
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import InputAdornment from "@material-ui/core/InputAdornment";
import FilledInput from "@material-ui/core/FilledInput";
import InputLabel from "@material-ui/core/InputLabel";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Tooltip from '@material-ui/core/Tooltip';
import { resetAdminPassword } from '../../actions/admin.js'
import { validatePassword } from "../../utils/helpers";

const useStyles = makeStyles(theme => ({
  textField: {
    width: 250,
  },
  formControl: {
    marginBottom: 40,
    marginTop: 20,
    marginRight: 45,
  },
  content: {
    overflow: 'hidden',
  }
}));

const mapDispatchToProps = dispatch => {
  return {
    resetAdminPassword: (id, password) => dispatch(resetAdminPassword(id, password))
  }
}

const mapStateToProps = state => ({
  passwordConfig: pathOr({}, ["passwordConfig"], state)
});

export default connect(mapStateToProps, mapDispatchToProps)((props) => {
  const classes = useStyles();
  const [values, setValues] = React.useState({
    password: '',
    passwordError: false,
    showPassword: false
  });

  const handleChange = name => event => {
    setValues({ ...values, [name]: event.target.value });
  };

  const handleSubmit = event => {
    if (validatePassword(props.passwordConfig, values.password)) {
      setValues({ ...values, passwordError: false, password: '' });
      props.resetAdminPassword(props.id, values.password)
      props.onClose();
    } else {
      setValues({ ...values, passwordError: true });
    }
  }

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  const password_length_message = "Password must be " + props.passwordConfig.minlength + "-" + props.passwordConfig.maxlength + " characters."
  let password_requirements_message;
  if (props.passwordConfig.isspcharrequired && props.passwordConfig.isnumrequired && props.passwordConfig.iscapletterrequired) {
    password_requirements_message = "You must also include at least one capital letter, number, and special character."
  } else if (!props.passwordConfig.isspcharrequired && props.passwordConfig.isnumrequired && props.passwordConfig.iscapletterrequired) {
    password_requirements_message = "You must also include at least one capital letter and number."
  } else if (props.passwordConfig.isspcharrequired && !props.passwordConfig.isnumrequired && props.passwordConfig.iscapletterrequired) {
    password_requirements_message = "You must also include at least one capital letter and special character."
  } else if (props.passwordConfig.isspcharrequired && props.passwordConfig.isnumrequired && !props.passwordConfig.iscapletterrequired) {
    password_requirements_message = "You must also include at least one number and special character."
  } else if (!props.passwordConfig.isspcharrequired && !props.passwordConfig.isnumrequired && props.passwordConfig.iscapletterrequired) {
    password_requirements_message = "You must also include at least one capital letter."
  } else if (props.passwordConfig.isspcharrequired && !props.passwordConfig.isnumrequired && !props.passwordConfig.iscapletterrequired) {
    password_requirements_message = "You must also include at least one special character."
  } else if (!props.passwordConfig.isspcharrequired && props.passwordConfig.isnumrequired && !props.passwordConfig.iscapletterrequired) {
    password_requirements_message = "You must also include at least one number."
  } else {
    password_requirements_message = " "
  }

  return (
    <div>
      <input style={{ opacity: 0, position: 'absolute' }} />
      <input type="password" style={{ opacity: 0, position: 'absolute' }} />
      <Dialog open={props.open} onClose={props.onClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Reset Admin Password</DialogTitle>
        <DialogContent className={classes.content}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              {/* <TextField
                id="filled-password"
                className={classes.textField}
                label="New Password"
                value={values.password}
                onChange={handleChange('password')}
                margin="normal"
                variant="filled"
                error={values.passwordError}
                helperText={values.passwordError ? "Must be at least 9 characters" : ''}
                type="password"
              /> */}
              <Tooltip title={password_length_message + " " + password_requirements_message} placement="bottom-start" open={values.passwordError}>
                <FormControl className={classes.formControl}>
                  <InputLabel htmlFor="adornment-password">New Password</InputLabel>
                  <FilledInput
                    id="filled-password"
                    className={classes.textField}
                    type={values.showPassword ? "text" : "password"}
                    value={values.password}
                    onChange={handleChange("password")}
                    error={values.passwordError}
                    autoComplete="password"
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {values.showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </Tooltip>
            </Grid>
          </Grid>

        </DialogContent>
        <DialogActions>
          <Button onClick={props.onClose}>
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="secondary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div >
  );
})
