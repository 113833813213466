import React, { useState } from "react";
import styled from "styled-components";
import pathOr from "ramda/src/pathOr";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import FilledInput from "@material-ui/core/FilledInput";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";
import FormContainer from "../login/FormContainer";
import { validateEmail } from "../../utils/helpers";
import { resetPassword } from "../../actions/auth";
import { palette } from "../../styles/variables";

const Message = styled.p`
  text-align: left;
  margin-bottom: 20px;
  color: ${props => props.error ? palette.red : 'inherit'};
`

const useStyles = makeStyles(theme => ({
  formControl: {
    width: "100%",
    marginBottom: "40px"
  },
  button: {
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: 1.14,
    letterSpacing: "1.25px",
    padding: "16px",
    width: "100%"
  }
}));

const mapStateToProps = state => ({
  loading: pathOr(false, ["auth", "loading"], state),
  errorMessage: pathOr(null, ["auth", "error"], state),
});

const mapDispatchToProps = dispatch => ({
  resetPassword: (email) => dispatch(resetPassword(email))
});

export default connect(mapStateToProps, mapDispatchToProps)(({loading, errorMessage, resetPassword}) => {
  const classes = useStyles();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isAccepted, setIsAccepted] = useState(false);
  const [dirty, setDirty] = useState(false);
  const [error, setError] = useState(false);
  const [email, setEmail] = useState("");
  const handleChange = event => {
    setDirty(false);
    setError(!validateEmail(event.target.value));
    setEmail(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault()
    setIsSubmitted(true);
    setDirty(true);
    if (!error) {
      setIsAccepted(true)
      resetPassword(email)
    }
  };

  return (
    <FormContainer title="Forgot Password?">
      {isSubmitted && dirty && !loading && <Message error={errorMessage}>{isAccepted ? 'Check your email for the confirmation link.' : errorMessage}</Message>}
      <form noValidate onSubmit={handleSubmit}>
        <FormControl
          className={classes.formControl}
          variant="filled"
          error={isSubmitted && error}
        >
          <InputLabel htmlFor="email">Email Address</InputLabel>
          <FilledInput
            id="email"
            fullWidth
            value={email}
            onChange={handleChange}
            autoComplete="off"
          />
          {isSubmitted && error && (
            <FormHelperText id="email-error-text">
              Please enter a correct email format.
            </FormHelperText>
          )}
        </FormControl>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          className={classes.button}
          onClick={handleSubmit}
          disabled={loading || (isSubmitted && error)}
        >
          SUBMIT
        </Button>
      </form>
    </FormContainer>
  );
});
