import axios from 'axios';

axios.defaults.xsrfCookieName = 'csrftoken'
axios.defaults.xsrfHeaderName = 'X-CSRFToken'
axios.defaults.withCredentials = true

function config() {
  return {
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }
  };
}

function setPasswordConfig() {
  return {
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': 'token 227c7b7c1aeda68eb073fa3090318b6e710802f4'
    }
  };
}

function multipartConfig() {
  return {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  }
}

function multipartImportConfig() {
  return {
    headers: {
      'Content-Type': 'multipart/form-data; boundary=--------------------------491115805895882531887873',
      'Authorization': 'token 3c23f2a03d434800143c3eb58e4bd0668dceb455'
    }
  }
}

function handleError(err) {
  throw err.response;
}

function handleResponse(response) {
  return response.data;
}

function handleResponseStatus(response) {
  return response.status
}

export const getToken = () => localStorage.getItem("authToken")

export const setAuth = token => {
  if (token) {
    axios.defaults.headers.common["Authorization"] = `token ${token}`;
    localStorage.setItem("authToken", token);
  }
};

export const clearAuth = () => {
  delete axios.defaults.headers.common["Authorization"];
  localStorage.removeItem("authToken");
}

export function setPasswordRequest(url, data) {
  clearAuth()
  return axios
    .post(url, data, config())
    .then(handleResponse)
    .catch(handleError);
}

export function getRequest(url, setPass = false) {
  if (setPass) {
    return axios
      .get(url, setPasswordConfig())
      .then(handleResponse)
      .catch(handleError);
  }
  return axios
    .get(url, config())
    .then(handleResponse)
    .catch(handleError);
}

export function postRequest(url, data) {
  return axios
    .post(url, data, config())
    .then(handleResponse)
    .catch(handleError);
}

export function postMultipartRequest(url, data) {
  let formData = new FormData();
  for (var key in data) {
    formData.append(key, data[key])
  }
  return axios
    .post(url, formData, multipartConfig())
    .then(handleResponse)
    .catch(handleError)
}

export function postMultipartImportRequest(url, data) {

  let formData = new FormData();
  formData.append('file', data.file)

  return axios
    .post(url, formData, multipartImportConfig())
    .then(handleResponse)
    .catch(handleError)
}

export function putRequest(url, data) {
  return axios
    .put(url, data, config())
    .then(handleResponse)
    .catch(handleError);
}

export function putMultipartRequest(url, data) {
  let formData = new FormData();
  for (var key in data) {
    formData.append(key, data[key])
  }
  return axios
    .put(url, formData, multipartConfig())
    .then(handleResponse)
    .catch(handleError)
}

export function deleteRequest(url) {
  return axios
    .delete(url, config())
    .then(handleResponse)
    .catch(handleError);
}