import React from "react"
import styled from "styled-components"
import Tabs from "../../../components/tabs/Tabs";
import Tab from "../../../components/tabs/Tab";
import TabPanel from "../../../components/tabs/TabPanel";
import { palette } from "../../../styles/variables";
import PatientForm from "../../../components/PatientForm";
import ResetPasswordForm from "../../../components/ResetPasswordForm";
import IconFootstep from "../../app/IconFootstep";
import ConfirmationDialog from "../../../components/ConfirmationDialog";

const Container = styled.div`
  border-radius: 4px;
  border: solid 1px ${palette.border};
  background-color: white;
  position: relative;
`

const Label = styled.div`
  position: absolute;
  right: 40px;
  top: 25px;
  display: flex;
  align-items: center;

  svg {
    path {
      fill: rgba(0, 0, 0, 0.6);
    }
  }

  span {
    color: rgba(0, 0, 0, 0.6);
    margin-right: 14px;
    &:last-of-type {
      margin-right: 9px;
    }
  }
`

const ResetContainer = styled.div`
  margin-top: 34px;
  margin-bottom: 20px;
  display: flex;
  align-items: left;
`

const Button = styled.button`
  line-height: 1.14;
  letter-spacing: 1.25px;
  margin-right: 40px;
  color: ${palette.gray};
  font-size: 14px;
  font-weight: 500;
  border: 0;
  cursor: pointer;
  background: transparent;
  text-transform: uppercase;
`

export default (props) => {
  const [tabValue, setTabValue] = React.useState(0);
  const [showResetPassword, setShowResetPassword] = React.useState(false);
  const [showDialog, setShowDialog] = React.useState(false);

  function handleTabChange(event, newValue) {
    setTabValue(newValue);
  }

  const handleDelete = () => {
    props.deleteUser(props.user.parent.user_id)
  }

  let parent = props.user.parent || {}
  let d = new Date(parent.created_at)
  return (
    <Container>
      <Label>
        <span style={{marginRight:"40px"}}>Created: {d.toLocaleDateString("en-US")}</span>
        <span>Clubfoot</span>
        <IconFootstep />
      </Label>
      <Tabs value={tabValue} onChange={handleTabChange} aria-label="Parent/Patient Info Box">
        <Tab label="Parent Info" />
      </Tabs>
      <TabPanel value={tabValue} index={0}>
        <PatientForm userType="clubfoot" user={props.user.parent} updateUser={props.updateUser}/>

        <ResetContainer>
          {/* <Button type="button" onClick={() => setShowResetPassword(!showResetPassword)}>Reset Password</Button> */}
          <Button type="button" onClick={() => setShowDialog(true)}>Delete User</Button>
        </ResetContainer>
        {showResetPassword && <ResetPasswordForm />}
      </TabPanel>
      <ConfirmationDialog open={showDialog} title="Delete User" operation={handleDelete} onClose={() => setShowDialog(false)} />
    </Container>
  )
}
