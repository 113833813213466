import React from 'react';
import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import IconSpine from '../app/IconSpine';

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  svg {
    path {
      fill: rgba(0, 0, 0, 0.6)
    }
  }
`

const useToolbarStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

const EnhancedTableToolbar = () => {
  const classes = useToolbarStyles();

  return (
    <Toolbar
      className={classes.root}
    >
      <span>Scoliosis</span>
      <IconContainer>
        <IconSpine />
      </IconContainer>
    </Toolbar>
  );
};

export default EnhancedTableToolbar;
