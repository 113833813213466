import React from "react"
import styled from "styled-components"
import { palette } from "../../styles/variables";

const Container = styled.div`
  width: 156px;
  height: 140px;
  border-radius: 4px;
  border: solid 1px ${palette.border};
  background-color: white;
  color: rgba(0, 0, 0, 0.6);
  text-align: center;
  padding: 20px 16px 16px;
  box-sizing: border-box;
  cursor: pointer;
  transition: all .2s ease-in-out;
  &:hover {
    box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px 0 rgba(0, 0, 0, 0.2);
    background-color: ${palette.blue};
    border: solid 1px ${palette.blue};
    color: white;
  }
`

const IconContainer = styled.div`
  svg {
    font-size: 48px;
  }
`

const Label = styled.label`
  font-size: 24px;
  line-height: 1.5;
  margin: 4px 0 8px;
  cursor: pointer;
`

const P = styled.p`
  font-size: 14px;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: 0.1px;
  cursor: pointer;
`

export default ({IconComponent, value, label}) => (
  <Container>
    <IconContainer>{IconComponent}</IconContainer>
    <Label>{value}</Label>
    <P>{label}</P>
  </Container>
)
