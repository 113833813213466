import React from "react"
import { connect } from "react-redux";
import pathOr from "ramda/src/pathOr";
import styled from "styled-components"
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FilledInput from '@material-ui/core/FilledInput';
import Button from '@material-ui/core/Button';
import { RadioGroup, Radio } from "@material-ui/core";
import { palette } from "../../../styles/variables";
import { updateAdmin, getAdmin, deleteAdmin } from '../../../actions/admin.js'
import ConfirmationDialog from "../../../components/ConfirmationDialog";
import ResetPasswordDialog from "../ResetPasswordDialog"

const useStyles = makeStyles(theme => ({
  textField: {
    width: '100%',
    margin: 0,
  },
  formControl: {
    marginTop: 16,
    width: '100%',
    paddingLeft: 10
  },
  gridItem: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  label: {
    color: 'rgba(0, 0, 0, 0.87)',
  },
  button: {
    padding: '10px 16px',
    width: 150
  },
  defaultButton: {
    padding: '10px 16px',
    width: 150,
    color: palette.gray,
    marginRight: 10
  },
  resetPasswordButton: {
    padding: '10px 16px',
    width: 200,
    color: palette.gray,
    marginRight: 10
  },
  checkbox: {
    width: 160,
  },
  buttonGroup: {
    paddingTop: "15px"
  }
}));

const CheckboxContainer = styled.div`
  display: flex;
`

const Container = styled.div`
  border-radius: 4px;
  border: solid 1px ${palette.border};
  background: white;
`

const Header = styled.div`
  padding: 24px;
  border-bottom: 1px solid ${palette.border};
`

const H1 = styled.h1`
  font-size: 20px;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: 0.15px;
  color: rgba(0, 0, 0, 0.87);
`

const H2 = styled.h2`
  font-size: 14px;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: 0.1px;
  color: rgba(0, 0, 0, 0.87);
`

const Main = styled.div`
  padding: 33px 24px;
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`
const mapStateToProps = state => ({
  auth: pathOr({}, ["auth"], state),
  admin: pathOr({}, ["admin", "admin"], state)
});

const mapDispatchToProps = dispatch => {
  return {
    getAdmin: (id) => dispatch(getAdmin(id)),
    updateAdmin: (id, admin) => dispatch(updateAdmin(id, admin)),
    deleteAdmin: (id) => dispatch(deleteAdmin(id))
  }
}

const AdminProfile = (props) => {
  const classes = useStyles();
  const [values, setValues] = React.useState({
    firstname: '',
    lastname: '',
    email: '',
    status: true,
    speciality: '',
    role: ''
  });

  const [showDialog, setShowDialog] = React.useState(false);
  const [showResetPassword, setShowResetPassword] = React.useState(false);

  React.useEffect(() => {
    props.getAdmin(props.match.params.id)
  }, [props.match.params.id])

  React.useEffect(() => {
    setValues({ ...props.admin })
  }, [props.admin])

  const handleChange = name => event => {
    let letter = ''
    if (name == "speciality") {
      if (values.speciality === "B") {
        letter = event.target.value === 'S' ? 'C' : 'S'
      } else if ((values.speciality === 'S' && event.target.value === 'C') || (values.speciality === 'C' && event.target.value === 'S')) {
        letter = 'B'
      } else {
        letter = event.target.value
      }
      setValues({ ...values, [name]: letter });
    } else {
      setValues({ ...values, [name]: event.target.value });
    }
  };

  const handleSave = event => {
    let admin = {
      firstname: values.firstname,
      lastname: values.lastname,
      // email: values.email,
      status: values.status,
      speciality: values.speciality,
      role: values.role,
    }
    props.updateAdmin(props.match.params.id, admin)
  }

  const handleDelete = event => {
    props.deleteAdmin(props.match.params.id)
  }

  let role_buttons;
  if (props.auth.role === "admin") {
    role_buttons = <Grid item xs={6} className={classes.gridItem}>
      <FormControl component="fieldset" className={classes.formControl}>
        <FormLabel component="legend" className={classes.label}>Role</FormLabel>
        <RadioGroup row value={values.role} onChange={handleChange('role')}>
          <FormControlLabel value="viewer" control={<Radio />} label="Viewer" />
          <FormControlLabel value="admin" control={<Radio />} label="Administrator" />
        </RadioGroup>
      </FormControl>
    </Grid>
  } else {
    role_buttons = <p></p>
  }

  if (props.admin && props.auth.role === "admin") {
    return (
      <Container>
        <Header>
          <H1>Admin Profile</H1>
        </Header>
        <Main>
          <H2>Profile Info</H2>
          <Grid container spacing={3}>
            <Grid item xs={6} className={classes.gridItem}>
              <TextField
                id="filled-firstname"
                className={classes.textField}
                label="First Name"
                value={values.firstname}
                onChange={handleChange('firstname')}
                margin="normal"
                variant="filled"
              />
            </Grid>
            <Grid item xs={6} className={classes.gridItem}>
              <TextField
                id="filled-lastname"
                className={classes.textField}
                label="Last Name"
                value={values.lastname}
                onChange={handleChange('lastname')}
                margin="normal"
                variant="filled"
              />
            </Grid>
            <Grid item xs={6} className={classes.gridItem}>
              <TextField
                id="filled-email"
                className={classes.textField}
                label="Email Address"
                value={values.email}
                disabled
                onChange={handleChange('email')}
                margin="normal"
                variant="filled"
              />
            </Grid>
            <Grid item xs={6} className={classes.gridItem}>
              <FormControl variant="filled" className={classes.textField}>
                <InputLabel htmlFor="filled-age-simple">Status</InputLabel>
                <Select
                  value={values.status}
                  onChange={handleChange('status')}
                  input={<FilledInput name="age" id="filled-age-simple" />}
                >
                  <MenuItem value={true}>Active</MenuItem>
                  <MenuItem value={false}>Inactive</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6} className={classes.gridItem}>
              <FormControl component="fieldset" className={classes.formControl}>
                <FormLabel component="legend" className={classes.label}>Specialty</FormLabel>
                <CheckboxContainer>
                  <FormControlLabel
                    className={classes.checkbox}
                    control={
                      <Checkbox
                        checked={values.speciality === 'C' || values.speciality === 'B'}
                        onChange={handleChange('speciality')}
                        value="C"
                        color="primary"
                      />
                    }
                    label="Club Foot"
                  />
                  <FormControlLabel
                    className={classes.checkbox}
                    control={
                      <Checkbox
                        checked={values.speciality === 'S' || values.speciality === 'B'}
                        onChange={handleChange('speciality')}
                        value="S"
                        color="primary"
                      />
                    }
                    label="Scoliosis"
                  />
                </CheckboxContainer>
              </FormControl>
            </Grid>
            {role_buttons}
          </Grid>
          <ButtonContainer className={classes.buttonGroup}>
            <Button className={classes.resetPasswordButton} onClick={() => setShowResetPassword(true)}>Reset Password</Button>
            <Button className={classes.defaultButton} onClick={() => setShowDialog(true)}>Remove</Button>
            <Button variant="contained" color="primary" className={classes.button} onClick={handleSave}>
              Save
            </Button>
          </ButtonContainer>
        </Main>
        <ResetPasswordDialog open={showResetPassword} onClose={() => setShowResetPassword(false)} id={props.match.params.id} />
        <ConfirmationDialog open={showDialog} title="Delete Admin" operation={handleDelete} onClose={() => setShowDialog(false)} />
      </Container>
    )
  } else {
    return (
      <h1>ERROR: Status Code 403 - You are not authorized to view this page.</h1>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminProfile)