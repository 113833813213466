import {
    GET_PASSWORD_CONFIG,
    UPDATE_PASSWORD_CONFIG
} from "./actionTypes";


export function getPasswordConfig(fromSetPassword = false) {
    if (fromSetPassword) return { type: GET_PASSWORD_CONFIG, payload: {}, fromSetPassword: true }
    return { type: GET_PASSWORD_CONFIG, payload: {}, fromSetPassword: false }
}

export function updatePasswordConfig(passwordConfig) {
    return { type: UPDATE_PASSWORD_CONFIG, payload: passwordConfig }
}