import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { palette } from "../../styles/variables"
import Logo from "../../assets/images/logo.png"

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh
`

const Container = styled.div`
  max-width: 540px;
  width: 100%;
  border-radius: 4px;
  border: solid 1px ${palette.border};
  background-color: ${palette.white};
  padding: 23px 32px;
  text-align: center;

  img {
    max-width: 212px;
  }
`

const H1 = styled.h1`
  font-size: 20px;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: 0.15px;
  color: rgba(0, 0, 0, 0.87);
  margin-top: 16px;
  margin-bottom: 24px;
`

const FormContainer = ({title, children}) => (
  <Wrapper>
    <Container>
      <img src={Logo} alt="Scottish Rite Hospital Logo" />
      <H1>{title}</H1>
      {children}
    </Container>
  </Wrapper>
)

FormContainer.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
}

export default FormContainer
