import React from "react";
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles(theme => ({
  textField: {
    width: '100%', 
    margin: '10px 0',
  },
  gridItem: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  button: {
    marginTop: 20,
    padding: '10px 16px',
    width: 150
  }
}));

export default () => {
  const classes = useStyles();
  const [values, setValues] = React.useState({
    password: '',
    confirmpassword: ''
  });

  const handleChange = name => event => {
    setValues({ ...values, [name]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };


  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={6} className={classes.gridItem}>
          <TextField
            id="filled-password"
            className={classes.textField}
            label="New Password"
            type={values.showPassword ? 'text' : 'password'}
            value={values.password}
            onChange={handleChange('password')}
            margin="normal"
            variant="filled"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    edge="end"
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {values.showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={6} className={classes.gridItem}>
          <TextField
            id="filled-confirm password"
            className={classes.textField}
            label="Repeat New Password"
            type={values.showPassword ? 'text' : 'password'}
            value={values.confirmpassword}
            onChange={handleChange('confirmpassword')}
            margin="normal"
            variant="filled"
          />
        </Grid>
      </Grid>
      <Button variant="contained" color="primary" className={classes.button}>
        Save
      </Button>
    </>
  )
}
