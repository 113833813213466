export const palette = {
  border: '#e5e5e5',
  gray: '#9e9e9e',
  white: '#ffffff',
  blue: '#2196f3',
  red: '#e53935',
  pantone: '#014e7b',
  skyBlue: '#e3f2fd',
  background: '#fbfcfd',
}
