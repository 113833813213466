import { createBrowserHistory } from 'history'
import createSagaMiddleware from 'redux-saga'
import rootSaga from './sagas'
import { applyMiddleware, compose, createStore } from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import { routerMiddleware } from 'connected-react-router'
import createRootReducer from './reducers'
import { RESET_APP } from './actions/actionTypes';
export const history = createBrowserHistory()

const persistConfig = {
  key: 'root',
  storage,
}

const sagaMiddleware = createSagaMiddleware()

const appReducer = createRootReducer(history);

const rootReducer = (state, action) => {
  if (action.type === RESET_APP) {
    state = undefined;
  }

  return appReducer(state, action);
}


const persistedReducer = persistReducer(persistConfig, rootReducer)


const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  // persistedReducer, // root reducer with router state
  rootReducer,
  composeEnhancers(
    applyMiddleware(
      sagaMiddleware,
      routerMiddleware(history), // for dispatching history actions
      // ... other middlewares ...
    ),
  ),
)

export const persistor = persistStore(store)

export default store;

sagaMiddleware.run(rootSaga)
