import React from 'react';
import styled from 'styled-components';
import { lighten, makeStyles, withStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import GetAppIcon from '@material-ui/icons/GetApp';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';

const Span = styled.span`
  margin-right: 6px;
`

const BootstrapInput = withStyles(theme => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}))(InputBase);

const useToolbarStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    display: 'flex',
    justifyContent: 'space-between',
  },
  highlight:
    theme.palette.type === 'light'
      ? {
        color: theme.palette.secondary.main,
        backgroundColor: lighten(theme.palette.secondary.light, 0.85),
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark,
      },
  actions: {
    color: theme.palette.text.secondary,
  },
  title: {
    flex: '0 0 auto',
  },
  button: {
    padding: 0,
    width: 100,
    height: 30,
    lineHeight: '30px',
    float: 'right'
  },
}));

const EnhancedTableToolbar = ({ onChangePatientType, showDownload, exportUsers }) => {
  const classes = useToolbarStyles();
  const [value, setValue] = React.useState('all');
  const handleChange = event => {
    setValue(event.target.value);
    onChangePatientType(event.target.value);
  };

  return (
    <Toolbar
      className={classes.root}
    >
      <div className={classes.title} >
        <Span>Patient Type:</Span>
        <Select
          value={value}
          onChange={handleChange}
          input={<BootstrapInput name="age" id="age-customized-select" />}
        >
          <MenuItem value="all">All</MenuItem>
          <MenuItem value="scoliosis">Scoliosis</MenuItem>
          <MenuItem value="clubfoot">Club Foot</MenuItem>
        </Select>
      </div>
    </Toolbar>
  );
};

export default EnhancedTableToolbar;
